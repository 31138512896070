import { _request } from '@@/utils/request'
import CONFIG from '@@/config'

export const {
  CUSTOMER_HOST
} = CONFIG

export const customerRequest = {
  get: (url, data, config = {}) => _request.get(`${CUSTOMER_HOST}${url}`, data, { ...config }),
  post: (url, data, config = {}) => _request.post(`${CUSTOMER_HOST}${url}`, data, { ...config })
}

// 获取下单人信息列表
export const getOrderManPage = params => customerRequest.get('/web/order/man/query/page', params)
// 添加下单人信息
export const addOrderMan = data => customerRequest.post('/web/order/man/insert', data)

// 获取工程师信息
export const getEngineerList = data => customerRequest.post(`/web/engineer/getListByHqId?t=${+new Date()}`, data)
// 添加工程师信息
export const addEngineer = data => customerRequest.post('/web/engineer/insert', data)

// 获取发票列表
export const getInvoiceList = params => customerRequest.get('/web/invoice/query/list', params)
// 新增发票
export const addInvoice = data => customerRequest.post('/web/invoice/insert', data)
// 更新发票
export const updateInvoice = data => customerRequest.post('/web/invoice/update', data)

// 地址列表
export const getNewAddressList = params => customerRequest.get('/web/index/user/address/list', params)
// 添加地址
export const addNewAddressInfo = data => customerRequest.post('/web/index/user/address/insert', data)

// layout获取默认下单人
export const getLayoutOrderMan = data => customerRequest.post('/web/order/man/query/page?pageNum=1&pageSize=100', data)
