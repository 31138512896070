import { isPcbSite, isSmtSite, isMyCenterSite } from '@@/utils/is'

// 顶部滚动到指定位置
export const scrollTop = (top, offset = 0) => {
  // PCB站点/SMT站点
  if (isPcbSite || isSmtSite) {
    top = top + window.scrollY + offset
    window.scrollTo({ top, behavior: 'smooth' })
  }
  // 用户中心站点
  if (isMyCenterSite) {
    const scrollBar = document.getElementById('mainPageScrollbar')
    if (scrollBar) {
      const scrollWrap = scrollBar.querySelector('.el-scrollbar__wrap')
      // 74: 固定顶部和标签栏高度
      top = top + scrollWrap.scrollTop + offset - 74
      scrollWrap.scrollTo({ top, behavior: 'smooth' })
    }
  }
}