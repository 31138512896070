import { _request } from '@@/utils/request'
import CONFIG from '@@/config'

export const {
  SMTSERVICE_HOST
} = CONFIG

export const smtRequest = {
  get: (url, data, config = {}) => _request.get(`${SMTSERVICE_HOST}${url}`, data, {...config}),
  post: (url, data, config = {}) => _request.post(`${SMTSERVICE_HOST}${url}`, data, {...config})
}

// 获取BOM订单列表
export const getBomPage = data => smtRequest.post('/smtservice/app/buildOrder/getBomPage', data)
// 获取pcb订单列表
export const getPcbPage = data =>smtRequest.post('/smtservice/app/buildOrder/getPcbPage', data)
// 获取SMT计价
export const getSmtPrice = data =>smtRequest.post('/smtservice/app/tools/getPricing', data)
// 保存SMT计价信息
export const saveSmtQuote = data =>smtRequest.post('/smtservice/app/tools/saveSmtTmpOrder', data)
// 获取SMT计价信息
export const getSmtQuote = data =>smtRequest.post('/smtservice/app/v2/smtTmp/getParams', data)
// 提交SMT订单
export const submitSmtQuote = data =>smtRequest.post('/smtservice/app/tools/saveOrder', data)
// 获取SMT快递列表
export const getSmtExpress = data =>smtRequest.post('/smtservice/app/tools/expressList', data)
