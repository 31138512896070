
// SMT参数格式化
export const formatSmtParams = (smtParams, otherParams = {}) => {
  const params = { ...smtParams, ...otherParams }
  const { order_info, address, invoice, pcbParams, ...smt_params } = params

  // // 单片贴片点数：用户不填时，默认为1
  // smt_params.patch_pad_number = smt_params.patch_pad_number || 1
  // // 单片插件点数：用户不填时，焊接插件料 === 否，默认为0；焊接插件料 === 是，默认为1
  // smt_params.plug_number = !smt_params.is_plug ? 0 : smt_params.plug_number || 1
  // 板面清洁：焊接插件料 === 否，默认为0
  smt_params.is_layout_cleaning = !smt_params.is_plug ? 0 : smt_params.is_layout_cleaning

  // PCB 自己提供
  if (smt_params.custom_pcb_ban == 3) {
    // 拼版数
    smt_params.splicing_number = (smt_params.pcb_ban_x || 1) * (smt_params.pcb_ban_y || 1)
  } else {
    // 拼版数
    smt_params.splicing_number = ''
    // PCB拼版尺寸 长
    smt_params.pcb_ban_width = ''
    // PCB拼版尺寸 宽
    smt_params.pcb_ban_height = ''
    // PCB拼版规则 x方向个数
    smt_params.pcb_ban_x = ''
    // PCB拼版规则 y方向个数
    smt_params.pcb_ban_y = ''
  }
 
  // 将 order_info, address, invoice 提取到与 smt_params 同级
  return {
    order_info,
    address,
    invoice,
    smt_params,
    ...(smt_params.custom_pcb_ban == 2 ? { pcb_params: pcbParams } : {})
  }
}

// SMT计价页下单校验
export const validateSmtQuote = params => {
  const valids = [
    { 
      key: 'application_sphere',
      flag: !params.application_sphere, 
      tips: '请选择产品领域'
    },
    { 
      key: 'custom_pcb_ban',
      flag: !params.custom_pcb_ban, 
      tips: '请选择PCB'
    },
    { 
      key: 'custom_pcb_ban',
      flag: params.custom_pcb_ban == 2 && (!params.pcbParams || !params.pcbParams.order_id), 
      tips: '请选择PCB订单'
    },
    { 
      key: 'custom_pcb_ban',
      flag: params.custom_pcb_ban != 2 && !params.file_pcb, 
      tips: '请上传PCB文件'
    },
    { 
      key: 'buy_bom',
      flag: !params.buy_bom, 
      tips: '请选择BOM物料'
    },
    { 
      key: 'buy_bom',
      flag: params.buy_bom == 3 && (!params.order_info || !params.order_info.bom_id), 
      tips: '请选择BOM订单'
    },
    { 
      key: 'buy_bom',
      flag: [1, 2, 4].includes(params.buy_bom) && !params.file_bom, 
      tips: '请上传BOM文件'
    },
    { 
      key: 'buy_bom',
      flag: !params.file_patch, 
      tips: '请上传坐标文件'
    },
    { 
      key: 'number',
      flag: !params.number, 
      tips: '请输入生产数量'
    },
    { 
      key: 'pcb_ban_num',
      flag: params.custom_pcb_ban == 3 && (!params.pcb_ban_x || !params.pcb_ban_y), 
      tips: '请填写PCB拼版规则'
    },
    { 
      key: 'pcb_ban_size',
      flag: params.custom_pcb_ban == 3 && (!params.pcb_ban_width || !params.pcb_ban_height), 
      tips: '请填写PCB拼版尺寸'
    },
    { 
      key: 'pcb_ban_size',
      flag: () => { 
        const [minVal, maxVal] = [params.pcb_ban_width, params.pcb_ban_height].sort((a, b) => a - b)
        return params.custom_pcb_ban == 3 && (minVal < 5 || maxVal < 5 || minVal > 46 || maxVal > 49)
      }, 
      tips: 'PCB拼版尺寸不能小于5cm，长边不能大于49cm，短边不能大于46cm',
    },
    { 
      key: 'bom_material_type_number',
      flag: !params.bom_material_type_number, 
      tips: '请填写BOM物料'
    },
    { 
      key: 'solder_paste_type',
      flag: !params.solder_paste_type, 
      tips: '请选择制程要求'
    },
    { 
      key: 'x_ray',
      flag: !params.x_ray_number && params.x_ray_unit_number || params.x_ray_number && !params.x_ray_unit_number, 
      tips: 'X-RAY的元件数量与单板数量需同时为空或同时为0，或同时大于0' 
    },
    { 
      key: 'x_ray',
      flag: params.x_ray_unit_number > params.number, 
      tips: 'X-RAY单板数量不能大于单板数量' 
    },
    { 
      key: 'is_first_confirm',
      flag: !params.is_first_confirm && params.is_first_confirm !== 0, 
      tips: '请选择样品图确认'
    },
  ]

  return valids.filter(item => {
    const flag = typeof item.flag === 'function' ? item.flag() : item.flag
    return flag
  })
}

// 快板免费加急活动提示
export const formatDeliveryTips = (activity = {}) => {
  const smtActivity = activity.smt || []
  const item = smtActivity.find(item => item.code === 'S10001')
  if (item) {
    const delivery = { 3: '免费12H加急', 4: '免费24H加急' }[item.type]
    return delivery ? `当前参数满足${delivery}活动，最终实际生产周期以审核为准` : ''
  }
  return ''
}
