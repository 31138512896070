// FPC参数处理

// 是否显示判断
export const isShowFpcParams = params => ({
  // 是否显示拼版规则
  layout: params.units == 2,
  // 是否显示工艺边框
  sidewidth: params.units != 3,
  // 是否显示内层铜厚
  insidecopper: params.blayer > 2,
  // 是否显示沉金厚度
  cjh: params.spray === '沉金',
  // 是否显示电金厚度
  electrogilding_thickness: params.spray === '电金',
  // 是否显示补强类型
  reinforce_type: params.reinforce == 1,
  // 是否显示层压顺序
  overlay_type: params.blayer > 2,
  // 是否显示报告形式
  report_type: ['出货报告', '切片报告', '阻抗报告'].some(i => params.report.includes(i)),
})

// FPC 板子层数为 8 10 12 价格以审核为准
export const isFpcNeedAudit = (type, params) => {
  return type.includes('FPC') && [8, 10, 12].includes(Number(params.blayer))
}

// FPC参数格式化
export const formatFpcParams = (pcbParams, otherParams = {}) => {
  const params = { ...pcbParams, ...otherParams }
  const isShow = isShowFpcParams(params)
  // 拼版规则隐藏时，默认为1
  if (!isShow.layout) {
    params.layoutx = 1
    params.layouty = 1
  }
  // 工艺边框
  if (!isShow.sidewidth) {
    params.sidedirection = '无'
    params.layouty = 0
  }
  // 当内层铜厚隐藏时，传 0
  if (!isShow.insidecopper) {
    params.insidecopper = 0
  }
  // 当报告形式隐藏时，传 0
  if (!isShow.report_type) {
    params.report_type = 0
  }
  // 沉金厚度
  if (!isShow.cjh) {
    params.cjh = 0
  }
  // 电金厚度
  if (!isShow.electrogilding_thickness) {
    params.electrogilding_thickness = 0
  }
  // 报告形式
  if (!isShow.report_type) {
    params.report_type = 0
  }

  // 产品报告
  params.report = params.report.includes('无需') ? '' : params.report.join(',')

  return params
}

// FPC计价页下单校验
export const validateFpcQuote = params => {
  const isShow = isShowFpcParams(params)
  const valids = [
    {
      key: 'pbnum',
      flag: !params.pbnum, 
      tips: '请输入拼版款数'
    },
    {
      key: 'pbnum',
      flag: isNaN(Number(params.pbnum)) || Number(params.pbnum) <= 0 || Number(params.pbnum) % 1 !== 0, 
      tips: '拼版款数，需为1的正整数倍，请修改拼版款数'
    },
    {
      key: 'pbnum',
      flag: params.blayer > 4 && params.pbnum > 1, 
      tips: '板子层数大于4层时，拼版款数不能大于1'
    },
    {
      key: 'layout',
      flag: isShow.layout && params.layoutx < 2 && params.layouty < 2, 
      tips: '拼版规则需要大于1x1'
    },
    {
      key: 'length',
      flag: isNaN(Number(params.length)) || Number(params.length) <= 0 || isNaN(Number(params.width)) || Number(params.width) <= 0, 
      tips: '请输入板子大小'
    },
    {
      key: 'bcount',
      flag: params.bcount <= 0, 
      tips: '请选择板子数量'
    },
    {
      key: 'sidewidth',
      flag: params.sidedirection != '无' && params.sidewidth < 3, 
      tips: '工艺边框最小3mm'
    },
    {
      key: 'sidewidth',
      flag: params.sidedirection != '无' && params.sidewidth * 10 % 5 != 0, 
      tips: '工艺边框仅支持0.5的倍数，比如3.5, 4 , 4.5'
    },
    {
      key: 'bheight',
      flag: !params.bheight, 
      tips: '请选择板子厚度'
    },
    {
      key: 'reinforce',
      flag: ![0, 1].includes(params.reinforce), 
      tips: '请选择补强'
    },
    {
      key: 'reinforce_type',
      flag: () => {
        if (!isShow.reinforce_type) return false
        return !(['steel', 'aluminium', 'fr4', 'fingerpi', 'otherpi'].some(key => {
          return params[`reinforce_${key}`] === 1 && (key === 'fingerpi' || !!params[`${key}_thickness`])
        }))
      }, 
      tips: '请选择补强类型'
    },
    {
      key: 'review_file',
      flag: !params.review_file && params.review_file !== 0, 
      tips: '请选择确认生产稿'
    },
    {
      key: 'report_type',
      flag: isShow.report_type && !params.report_type, 
      tips: '请选择报告形式'
    },
    {
      key: 'pi_thickness',
      flag: !params.pi_thickness, 
      tips: '请选择PI 厚度'
    },
    {
      key: 'electromagnetic_membrane',
      flag: !params.electromagnetic_membrane && params.electromagnetic_membrane !== 0, 
      tips: '请选择电磁膜'
    },
    {
      key: 'conducting_resin',
      flag: !params.conducting_resin && params.conducting_resin !== 0,
      tips: '请选择导电胶'
    },
    {
      key: 'test',
      flag: !params.test,
      tips: '请选择测试方式'
    },
    {
      key: 'overlay_type',
      flag: isShow.overlay_type && !params.overlay_type, 
      tips: '请选择层压顺序'
    },
    {
      key: 'overlay_type',
      flag: isShow.overlay_type && params.overlay_type == 3 && !params.overlay_seq, 
      tips: '请填写层压顺序'
    },
    {
      key: 'cross_board',
      flag: !params.cross_board, 
      tips: '请选择打叉板'
    },
    {
      key: 'is_need_smt',
      flag: !params.is_need_smt, 
      tips: '请选择是否需要我司做SMT'
    },
  ]

  return valids.filter(item => {
    const flag = typeof item.flag === 'function' ? item.flag() : item.flag
    return flag
  })
}
