import { _request } from '@@/utils/request'
import CONFIG from '@@/config'

export const {
  HQPCBA_HOST
} = CONFIG

export const pcbaRequest = {
  get: (url, data, config = {}) => _request.get(`${HQPCBA_HOST}${url}`, data, {...config}),
  post: (url, data, config = {}) => _request.post(`${HQPCBA_HOST}${url}`, data, {...config})
}

// 初始化BOM自助选型
export const bomInitialization = data => pcbaRequest.post('/pcba/app/bom/initialization', data)

// 获取pcb订单列表
export const getPcbOrdersToBind = data => pcbaRequest.post('/pcba/app/pcb/getPcbOrdersToBind', data)
// 获取BOM订单列表
export const getBomOrdersToBind = data => pcbaRequest.post('/pcba/app/bom/getBomOrdersToBind', data)
// 获取PCBA计价
export const getPcbaPrice = data => pcbaRequest.post('/pcba/app/tools/getPricing', data)
// 获取PCBA快递列表
export const getPcbaExpress = data => pcbaRequest.post('/pcba/app/tools/expressList', data)
// PCBA订单临时存储
export const saveOrderTemp = data => pcbaRequest.post('/pcba/app/temp/save', data)
// 获取PCBA订单临时数据
export const getOrderTemp = data => pcbaRequest.post('/pcba/app/temp/detail', data)
// BOM匹配后数据查看
export const checkBomMatchItem = data => pcbaRequest.post('/pcba/app/bom/checkBomMatchItem', data)
// BOM匹配后数据查看
export const bomSmtTemplateDownload = data => pcbaRequest.post('/pcba/app/bom/bomSmtTemplateDownload', data)
// 提交PCBA订单
export const submitPcbaOrder = data => pcbaRequest.post('/pcba/app/oneStop/saveDemand', data)

// 获取返单资料信息
export const getReOrderParams = data => pcbaRequest.post('/pcba/app/demand/getReOrderParams', data)
// 校验PCB是否能返单
export const checkPcbReturnOrder = data => pcbaRequest.post('/pcba/app/demand/checkPcbReturnOrder', data)
// 返单生成需求单
export const savePcbaReturnOrder = data => pcbaRequest.post('/pcba/app/demand/saveReturnOrder', data)
