<template>
  <BaseCascader
    v-model="val"
    placeholder="请选择"
    :props="optionsProps"
    :options="options"
    :filterable="filterable"
    :filter-method="filterMethod"
    :style="`width: ${width}`"
    @change="handleChange" >
  </BaseCascader>
</template>
<script>
import { getToken } from '@@/utils/auth'
import { getNewAddressList } from '@@/api/customer'
import { getRegionAll } from '@@/api/pcb'

export default {
  name: 'ChooseAddressCascader',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    // 省份选项
    provinceOptions: {
      type: Object,
      default: null
    },
    // 是否展示区
    showArea: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'auto'
    },
    filterable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      val: this.value,
      optionsProps: {
        label: 'regionName',
        value: 'id'
      },
      options: [],
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleChange([province, city, district]) {
      // 处理为空的情况
      if (province == void(0)) {
        this.$emit('change', {})
      } else {
        const chooseProvince = this.options.filter(provinceItem => provinceItem.id == province)[0]
        const chooseCity = chooseProvince.children.filter(cityItem => cityItem.id == city)[0]
        // 区分二级和三级返回内容
        if (this.showArea) {
          const chooseDistrict = chooseCity.children.filter(DistrictItem => DistrictItem.id == district)[0]
          this.$emit('change', { province, city, district, province_name: chooseProvince.regionName, city_name: chooseCity.regionName, district_name: chooseDistrict.regionName })
        } else {
          this.$emit('change', { province, city, province_name: chooseProvince.regionName, city_name: chooseCity.regionName })
        }
      }
    },
    // 设置筛选数据源 默认返回3级处理下子级内容
    setOptions(data) {
      if (this.showArea) {
        // 下面拼接拼音给到最后 放在最后一级给搜索使用
        this.options = data.map(provinceItem => {
          if (provinceItem.children) {
            let provincePinyinInitial = provinceItem.pinyinInitial
            provinceItem.children.map(cityItem => {
              if (cityItem.children) {
                let cityPinyinInitial = cityItem.pinyinInitial
                cityItem.children.map(districtItem => {
                  let districtPinyinInitial = districtItem.pinyinInitial
                  districtItem.pinyin = provincePinyinInitial + cityPinyinInitial + districtPinyinInitial
                  return districtItem
                })
              }
              return provinceItem
            })
          }
          return provinceItem
        })
      } else {
        this.options = data.map(provinceItem => {
          if (provinceItem.children) {
            let provincePinyinInitial = provinceItem.pinyinInitial
            provinceItem.children.map(cityItem => {
              let cityPinyinInitial = cityItem.pinyinInitial
              cityItem.pinyin = provincePinyinInitial + cityPinyinInitial
              // 去掉子级
              delete cityItem.children
              return cityItem
            })
          }
          return provinceItem
        })
      }

      this.getDefaultValue()
    },
    // 自定义筛选方法
    filterMethod(node, keyword) {
      // 判断是否字母使用拼音搜索
      if (/^[a-zA-Z]*$/.test(keyword)) {
        // 内容转小写
        keyword = keyword.toLowerCase()
        if (node.data.pinyin.indexOf(keyword) > -1) {
          return true
        } else {
          return false
        }
      } else {
        // 常规匹配
        if (node.text.indexOf(keyword) > -1) {
          return true
        } else{
          return false
        }
      }
    },
    // 获取默认地址的省市区
    getDefaultValue() {
      if (!getToken()) return
      getNewAddressList({
        pageNum: 1,
        pageSize: 10
      })
        .then(res => {
          if (!res) return
          const { suc, body = [] } = res
          if (suc) {
            const defaultAddrss = body.find(item => item.isDefault) || body[0]
            if (defaultAddrss) {
              let val = [+defaultAddrss.province, +defaultAddrss.city]
              if (this.showArea) {
                val.push(+defaultAddrss.district)
              }
              this.val = val
              this.handleChange(val)
            }
          }
        })
    },
    init() {
      // 需要注意双向绑定导致的关联问题
      if (this.$store.state.orderRegion.orderRegionOptions) {
        const options = this.$store.state.orderRegion.orderRegionOptions
        this.setOptions(JSON.parse(JSON.stringify(options)))
      } else {
        // 初始化获取所有参数
        getRegionAll()
          .then(res => {
            if (!res) return
            const { status, data = [] } = res || {}
            if (status === 1) {
              this.$store.dispatch('orderRegion/setOrderRegionOptions', JSON.parse(JSON.stringify(data)))
              this.setOptions(data)
            }
          })
      }
    },
  },
  mounted() {
    this.init()
  }
}
</script>