<template>
  <!-- PCB优惠券 -->
  <div
    ref="coupon"
    class="coupon"
    :class="{ disabled: isDisable }"
    @click="handerSelect"
  >
    <!-- 优惠券折扣金额 -->
    <div
      class="coupon__num"
      :class="{ 'show-info': isShowInfo }"
    >
      <!-- 优惠券标识 -->
      <span class="coupon__num__mark">
        {{ { 3: '折扣券', 4: '一口价' }[coupon.use_type] || '满减券' }}
      </span>
      <p class="coupon__num__money">
        <!-- 折扣券 -->
        <template v-if="coupon.use_type == 3">
          <span class="money">{{ coupon.coupon_discount }}折</span>
        </template>
        <!-- 一口价 -->
        <template v-else-if="coupon.use_type == 4">
          <span class="unit">￥</span><span class="money">{{ coupon.coupon_amount }}</span>
        </template>
        <template v-else>
          <span class="unit">￥</span><span class="money">{{ coupon.money }}</span>
        </template>
      </p>
      <p class="coupon__num__remark">
        {{ `满${coupon.order_amount_limit}元可用` }}
      </p>
    </div>

    <!-- 优惠券内容 -->
    <div
      class="coupon__content"
      :class="{ 'show-info': isShowInfo }"
    >
      <div class="title">
        <span v-if="coupon.coupon_type_title">{{ coupon.coupon_type_title }}</span>
        {{ coupon.name }}
      </div>
      <p class="time">
        {{ `${coupon.use_start_date_cn} - ${coupon.use_end_date_cn}` }}
      </p>
      <p class="tips">
        <el-popover
          v-if="coupon.faildmsg"
          placement="top"
          trigger="hover"
          popper-class="tips__popover"
          :content="coupon.faildmsg"
        >
          <span slot="reference">
            <i class="el-icon-warning-outline" />
            {{ coupon.faildmsg }}
          </span>
        </el-popover>
      </p>
      <p v-if="detailInfo.length" ref="detailInfoHandle" class="info" @click.stop="handleShowInfo">
        详细信息
        <i :class="`el-icon-caret-${isShowInfo ? 'top' : 'bottom'}`" />
      </p>
    </div>
    <!-- 选中icon -->
    <i v-if="isSelected" class="coupon__selected el-icon-success" />
    <!-- 详细信息 -->
    <Teleport v-if="isShowInfo" to="body">
      <div ref="detailInfoContent" class="coupon__info" :style="couponInfoStyle">
        <template v-if="detailInfo.length">
          <p v-for="(item, index) in detailInfo" :key="index">
            {{ index + 1 }}. <span v-html="item" />
          </p>
        </template>
        <p>券编号：{{ coupon.type_id || '-' }}</p>
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from './teleport.vue'

export default {
  components: {
    Teleport
  },
  props: {
    coupon: {
      type: Object,
      default: () => ({})
    },
    // 是否是不可用状态
    isDisable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    // 优惠券的视图容器，用于判断优惠券底部是否在视图容器外
    viewContainer: {
      type: Element,
      default: null
    }
  },
  data() {
    return {
      isSelected: this.selected, // 是否是选中状态
      isShowInfo: false, // 是否显示详细信息
      couponInfoStyle: {} // 详细信息定位相关样式
    }
  },
  computed: {
    // 详细信息
    detailInfo() {
      const data = this.coupon.coupon_instruction
      return data ? Object.values(JSON.parse(data)).filter(i => !!i) : []
    }
  },
  watch: {
    selected(newVal) {
      this.isSelected = newVal
    },
    isShowInfo(newVal) {
      if (newVal) {
        document.addEventListener('scroll', this.handleScroll, true)
        document.addEventListener('click', this.handleDocumentClick, true)
      } else {
        document.removeEventListener('scroll', this.handleScroll, true)
        document.removeEventListener('click', this.handleDocumentClick, true)
      }
    }
  },
  methods: {
    handerSelect() {
      if ([0, 2].includes(this.coupon.disable_value)) return
      // this.isSelected = !this.isSelected
      this.$emit('select', !this.isSelected)
    },
    // 详细信息显示切换
    handleShowInfo() {
      this.isShowInfo = !this.isShowInfo
      this.$nextTick(() => {
        this.setCouponInfoStyle(this.$refs.coupon.getBoundingClientRect())
      })
    },
    // 监听滚动事件
    handleScroll() {
      // this.setCouponInfoStyle(this.$refs.coupon.getBoundingClientRect())
      // 滚动时，直接隐藏详细信息模块
      this.isShowInfo = false
    },
    // 设置详细信息定位相关样式
    setCouponInfoStyle({ left, bottom, width }) {
      const viewContainerRect = this.viewContainer && this.viewContainer.getBoundingClientRect()
      // 当有视图容器且当前优惠券底部超出视图容器时，隐藏详细信息模块
      if (viewContainerRect && (bottom < viewContainerRect.top || bottom > viewContainerRect.bottom)) {
        this.isShowInfo = false
      } else {
        this.couponInfoStyle = { position: 'fixed', left: `${left}px`, top: `${bottom}px`, width: `${width}px` }
      }
    },
    handleDocumentClick(e) {
      const handle = this.$refs.detailInfoHandle
      const content = this.$refs.detailInfoContent
      // 当点击的是详细信息之外时 关闭弹框
      if (
        !handle ||
        handle.contains(e.target) ||
        !content ||
        content.contains(e.target)) { return }
      this.isShowInfo = false
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  // cursor: pointer;
  &:hover {
    .coupon__content,
    .coupon__info {
      border-color: $--color-primary;
    }
  }
  &.disabled {
    cursor: not-allowed;
    .coupon__num {
      background-color: #b2b2b2;
      background-image: none;
      .coupon__num__mark {
        background: #878787;
      }
    }
    .coupon__content,
    .coupon__info {
      border-color: #FAE5E5;
    }
    .coupon__content {
      .title {
        >span {
          background: rgba(162, 162, 162, 0.10);
          color: #34373F;
        }
      }
    }
  }
  &.used {
    .coupon__selected {
      color: #e68080;
    }
  }

  $borderRadius: 4px;
  .coupon__num {
    position: relative;
    width: 130px;
    height: 120px;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    background-image: linear-gradient(90deg, #eb5b38, #d92718);
    color: #fff;
    text-align: center;
    &.show-info {
      border-bottom-left-radius: 0;
    }
    &::before {
      content: "";
      position: absolute;
      height: 50%;
      width: 0;
      top: 25%;
      left: -5px;
      border-right: 10px dotted #fff;
    }
    >p {
      white-space: nowrap;
    }
    .coupon__num__mark {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 60px;
      height: 22px;
      font-size: 14px;
      color: #fff;
      border-bottom-left-radius: 8px;
      background: linear-gradient(90deg,#ff7458 4%, #ff571a 94%);
    }
    .coupon__num__money {
      margin: 25px 0 12px;
      font-size: 24px;
      font-weight: 700;
      .unit {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        vertical-align: super;
      }
    }
    .coupon__num__remark {
      font-size: 12px;
    }
  }

  .coupon__content {
    box-sizing: border-box;
    width: calc(100% - 130px);
    height: 120px;
    padding: 18px 14px 0;
    border: 1px solid #FAE5E5;
    border-left: 0;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    font-size: 12px;
    &.show-info {
      border-bottom-right-radius: 0;
    }
    .title {
      font-weight: 700;
      color: #34373F;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      >span {
        display: inline-block;
        margin-right: 4px;
        padding: 2px 8px;
        text-align: center;
        border-radius: 2px;
        background-color: #FCEFEE;
        color: $--color-primary;
      }
    }
    .time {
      margin: 8px 0;
      color: #999999;
    }
    .tips {
      margin-bottom: 6px;
      height: 16px;
      color: $--color-primary;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .info {
      color: #666666;
      cursor: pointer;
    }
  }

  .coupon__selected {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 18px;
    color: $--color-primary;
  }
}
</style>

<style lang="scss">
.coupon__info {
  box-sizing: border-box;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #FAE5E5;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  line-height: 1.8;
  font-size: 12px;
  color: #666666;
  background-color: #fff;
  z-index: 9999;
}
.tips__popover {
  max-width: 500px;
}
</style>
