<!-- 发票类型 -->
<template>
  <div class="invoice-type">
    <BaseSelect 
      v-if="uiType === 'select'" 
      placeholder="请选择"
      width="100%"
      v-model="val" 
      @change="handleEmit"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </BaseSelect>
    <BaseRadioGroup 
      v-else
      v-model="val" 
      @change="handleEmit"
    >
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
      >{{ item.label }}</el-radio-button>
    </BaseRadioGroup>
    <span slot="other" class="invoice-type__amount">
      <template v-if="amount">
        税费：￥{{ amount.toFixed(2) }}
      </template>
    </span>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: '不需要'
    },
    hideOptios: {
      type: Array,
      default: () => []
    },
    uiType: {
      type: String,
      default: 'radio',
      validator: function (value) {
        return ['radio', 'select'].includes(value)
      }
    },
    amount: {
      type: [String, Number],
      default: 0
    },
  },
  data() {
    return {
      val: this.value,
    }
  },
  computed: {
    options() {
      return [
        { value: '不需要', label: '不开发票' },
        { value: '普票', label: '数电增值税（普通）发票' },
        { value: '增票全电', label: '数电增值税（专用）发票' },
      ].filter(item => !this.hideOptios.includes(item.value))
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    },
    options(options) {
      this.validVal()
    }
  },
  created() {
    this.validVal()
  },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
      this.$emit('change', val)
    },
    validVal() {
      const { val, hideOptios } = this
      if (val === '不需要' && hideOptios.includes('不需要')) {
        this.val = '增票全电'
        this.handleEmit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-type {
  .invoice-type__amount {
    margin-left: 10px;
  }
}
</style>