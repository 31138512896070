import CONFIG from '@@/config'
import { ORDER_TYPE_ENUMS } from '@@/utils/constant'
import { getToken } from '@@/utils/auth'

const getFromParam = () => {
  const host = location.host
  // 1-hqchip站点 2-hqpcb站点 3-smt站点
  if (host.includes('smt')) return 3
  return host.includes('hqchip') ? 1 : 2
}

// 拼接BOM选项跳转链接
export const getBomUrl = (params = {}) => {
  const parasmStr = Object.keys(params)
    .map(key => `${key}=${key === 'type' ? ORDER_TYPE_ENUMS[params.type] : params[key]}`)
    .join('&')
  return `${CONFIG.BOM_DOMAIN}/bom/index?${parasmStr}&from=${getFromParam()}&token=${getToken()}`
}
