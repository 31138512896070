<script>
export default {
  props: {
    /* 移动至哪个标签内，最好使用id */
    to: {
      type: String,
      required: true
    }
  },

  mounted() {
    document.querySelector(this.to).appendChild(this.$el)
  },

  destroyed() {
    try {
      document.querySelector(this.to).removeChild(this.$el)
    } catch (error) {} // eslint-disable-line
  },

  render() {
    return <div>{this.$scopedSlots.default()}</div>
  }
}
</script>
