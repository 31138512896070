<template>
  <AnimateNumber
    ref="myNum"
    :startVal="fromNum" 
    :endVal="toNum"
    :duration="500"
    :decimals="2"
    separator=""
    :useEasing="false"
  />
</template>
<script>
import AnimateNumber from './animateNumber.vue'

export default {
  props: {
    num: {
      type: Number,
      default: 0
    }
  },
  components: {
    AnimateNumber
  },
  data() {
    return {
      fromNum: 0,
      toNum: 0
    }
  },
  watch: {
    num(newVal, oldVal) {
      this.fromNum = oldVal
      this.toNum = newVal
    }
  }
}
</script>