// 防抖
export const debounce = (func, wait) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      func.apply(context, args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

// 节流
export const throttle = (func, wait) => {
  let context, args, timeout, throttling, more
  const whenDone = debounce(function () {
    more = throttling = false
  }, wait)
  return function () {
    context = this
    args = arguments
    const later = function () {
      timeout = null
      if (more) func.apply(context, args)
      whenDone()
    }
    if (!timeout) timeout = setTimeout(later, wait)
    if (throttling) {
      more = true
    } else {
      func.apply(context, args)
    }
    whenDone()
    throttling = true
  }
}

// 重量计算保留2位小数
export const calcWeight = (weight) => {
  if (isNaN(Number(weight))) {
    return '0.00'
  } else {
    return Number(weight).toFixed(2)
  }
}

// 校验是不是正整数
export const isPositiveInteger = num => /(^[1-9]\d*$)/.test(num)
