<!-- 特殊工艺 -->
<template>
  <BaseFormItem
    class="process"
    :label="fields.label"
  >
    <p class="process__tips"><span>请勾选</span>需要的特殊工艺</p>
    <ul class="process__list">
      <template v-for="(item, key) in list">
        <template v-if="key === 'is_layout_cleaning'">
          <li :key="key">
            <BaseCheckbox 
              border
              v-model="val[key]"
              :true-label="1"
              :false-label="0"
              @change="val => handleEmit(key)"
            >
              {{ item.label }}
            </BaseCheckbox>
            <BaseTipPopover v-if="item.tips" :content="item.tips" width="400px" />
          </li>
          <li class="process__list__separator">
            下单后由报价工程师评估进行收费：
          </li>
        </template>
        <!-- 插件料选不焊接时，隐藏板面清洁 -->
        <template v-else-if="key === 'is_layout_cleaning' ? is_plug === 1 : true">
          <li :key="key">
            <BaseCheckbox 
              border
              class="process__content__checkbox" 
              v-model="val[key]"
              :true-label="1"
              :false-label="0"
              @change="val => handleEmit(key)"
            >
              {{ item.label }}
            </BaseCheckbox>
            <BaseTipPopover v-if="item.tips" :content="item.tips" width="400px" />
          </li>
        </template>
      </template>
    </ul>
  </BaseFormItem>
</template>

<script>
import { getSMTFields } from '@@/utils/getFields'

export const list = {
  is_material_baking: {
    label: '物料烘烤',
    tips: '因客供物料来料散料或受潮氧化导致的焊接问题，我司不承担责任！'
  },
  need_split: {
    label: '拆板出货',
    tips: `
      指去除工艺边，将拼板拆分成单板进行出货；\n
      华秋制作的PCB一般默认加工艺边及为了生产效率会代为拼板；
    `
  },
  is_layout_cleaning: {
    label: '板面清洁',
    tips: `
      【普通清洁】：PCBA在DIP工序制造过程中，波峰焊使用的免洗助焊剂残留是允许被接受的，主要是波峰焊接会有一层雾状印迹，普通外观标准；制造过程中使用烙铁进行维修、焊接、补焊的作业，需要将助焊剂进行清洗，会有些水纹状残留及洗板水残留印迹，短期内有黏手感觉\n
      【精度清洁】：指客户自己不组装成品直接裸PCBA出货的，或客户对外观干净度要求超过普通标准的
    `
  },
  is_assembly_weld: {
    label: '装配焊接',
    tips: '即为两个或多个PCBA与PCBA之间的相互焊接'
  },
  is_assemble: {
    label: '成品组装',
    tips: ''
  },
  is_welding_wire: {
    label: '焊接线材',
    tips: '焊线即为将线材直接焊在电路板上'
  },
  is_increase_tinning: {
    label: '线路/焊盘加锡',
    tips: ''
  },
  is_test: {
    label: '功能测试',
    tips: '¥45/h，测试所需专用设备和测试治具由客户提供或我司代开立'
  },
  is_program_burning: {
    label: '程序烧录',
    tips: ''
  }
}
const porps = Object.keys(list).reduce((obj, key) => {
  obj[key] = { type: Number, default: 0 }
  return obj
}, {})

export default {
  props: {
    // 焊接插件料
    is_plug: {
      type: Number,
      default: 1
    },
    ...porps
  },
  data() {
    return {
      val: {
        ...Object.keys(list).reduce((obj, key) => {
          obj[key] = this.$props[key]
          return obj
        }, {})
      },
      list
    }
  },
  computed: {
    fields() {
      return getSMTFields('process')
    }
  },
  watch: {
    is_plug(is_plug) {
      if (!is_plug) {
        this.val.is_layout_cleaning = 0
        this.handleEmit('is_layout_cleaning')
      }
    },
    ...Object.keys(list).reduce((obj, key) => {
      obj[key] = function(val) {
        this.val[key] = val
      }
      return obj
    }, {})
  },
  methods: {
    handleEmit(key) {
      this.$emit(`update:${key}`, this.val[key])
    },
    handleEmitChange() {
      this.$emit('change', { ...this.val })
    },
  }
}
</script>

<style lang="scss" scoped>
.process {
  .process__tips {
    font-size: 12px;
    color: $--color-text-secondary;
    >span {
      color: $--color-warning;
    }
  }
  .process__list {
    display: flex;
    flex-wrap: wrap;
    width: 540px;
    padding: 10px 10px 0;
    border-radius: $--border-radius-base;
    background-color: $--background-hover;
    >li {
      margin-right: 20px;
      margin-bottom: 10px;
      &.process__list__separator {
        padding-top: 10px;
        width: 100%;
        line-height: 1;
        font-size: 12px;
        color: $--color-text-placeholder;
        border-top: 1px dashed $--border-color-base;
      }
      .base-tip-popover__icon {
        margin-left: 5px;
      }
    }
  }
}
</style>