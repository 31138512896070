<template>
  <el-dialog
    title="优惠券"
    width="900px"
    append-to-body
    custom-class="pcb-coupon-dialog"
    :visible.sync="isShow"
    @close="close"
  >
    <div class="pcb-coupon__container">
      <p class="pcb-coupon__container__tips">
        <i>*</i> 此为满足当前订单参数可用的优惠券，仅供参考不可选用。最终可选用的优惠券以审核结果为准。
      </p>
      <el-tabs v-model="isUsable" class="pcb-coupon__tabs">
        <el-tab-pane label="可用" name="usable_coupon"></el-tab-pane>
        <el-tab-pane label="不可用" name="not_usable_coupon"></el-tab-pane>
      </el-tabs>
      <el-scrollbar style="height: 400px">
        <div class="pcb-coupon__list">
          <template v-if="(couponData[isUsable] || []).length > 0">
            <Coupon
              v-for="item in couponData[isUsable]"
              :key="item.type_id"
              :coupon="item"
              :isDisable="isUsable === 'not_usable_coupon'"
            />
          </template>
          <div v-else class="pcb-coupon__nodata">
            暂无优惠券
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-dialog>
</template>

<script>
import Coupon from './coupon'
export default {
  components: {
    Coupon
  },
  data() {
    return {
      isShow: false,
      isUsable: 'usable_coupon', // usable_coupon-可用  not_usable_coupon-不可用
      couponData: {
        usable_coupon: [],
        not_usable_coupon: []
      },
    }
  },
  methods: {
    open(data) {
      this.couponData = Object.assign({ usable_coupon: [], not_usable_coupon: [] }, data || {})
      this.isShow = true
    },
    close() {
      this.isUsable = 'usable_coupon'
      this.couponData = { usable_coupon: [], not_usable_coupon: [] }
    }
  }
}
</script>

<style lang="scss">
.pcb-coupon-dialog {
  .el-dialog__header {
    padding: 20px 30px 10px;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 700;
      color: #434343;
    }
  }
  .el-dialog__body {
    padding: 0 30px 30px;
  }
}
</style>
<style lang="scss" scoped>
.pcb-coupon-dialog {
  .pcb-coupon__container__tips {
    margin-bottom: 10px;
    font-size: 12px;
    color: #AD8C6D;
    >i {
      color: $--color-primary;
    }
  }
  .pcb-coupon__tabs ::v-deep {
    .el-tabs__item {
      &.is-active,
      &:hover {
        color: $--color-primary;
      }
    }
    .el-tabs__active-bar {
      background-color: $--color-primary;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: #EBEEF5;
    }
  }
  .el-scrollbar ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .pcb-coupon__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .coupon {
      margin-bottom: 20px;
      width: calc(50% - 10px);
    }
  }
  .pcb-coupon__nodata {
    flex: 1;
    padding: 50px 0;
    text-align: center;
    color: #999;
  }
}
</style>