
// 下单来源
const place_source_type = {
  PCB: 'PCB_WEB_ONE',
  MYCENTER: 'PCB_UC'
}[process.env.VUE_APP_SITE]

// FR-4 默认参数
export const FR4_DEFAULT_FORM = {
  place_source_type,
  board_type: 'FR-4', // 产品类别
  pcbfile: '', // PCB文件
  blayer: 2, // 板子层数
  blind: 0, // HDI(盲埋孔)
  board_brand: '无要求', // 板材品牌
  board_tg: '', // TG值
  pbnum: 1, // 拼版款数
  units: 1, // 出货形式
  length: '', // 尺寸 长
  width: '',  // 尺寸 宽
  bcount: '', // 单片数量
  layoutx: 1, // 拼版规则 横片数量
  layouty: 1,  // 拼版规则 纵片数量
  sidedirection: '无', // 工艺边框 方向
  sidewidth: 0, // 工艺边框 宽度
  bheight: 1.6, // 板子厚度
  copper: 1, // 外层铜厚
  insidecopper: 0.5, // 内层铜厚
  lineweight: 6, // 最小线宽/线距
  vias: 0.3, // 最小孔径
  zknum: 0, // 孔密度
  color: '绿色', // 阻焊颜色
  charcolor: '白色', // 字符颜色
  cover: '过孔盖油', // 阻焊覆盖
  spray: '有铅喷锡', // 表面处理
  cjh: 1, // 沉金厚度
  overlay_type: 2, // 层压顺序
  overlay_seq: '', // 层压顺序
  impendance: 0, // 阻抗
  pressing: '无需求', // 叠层结构
  beveledge: 0, // 手指斜边
  bankong: 0, // 半孔
  baobian: 0, // 包边
  via_in_pad: '无', // 盘中孔
  test: '样品免费测试', // 测试方式
  review_file: '', // 确认生产稿
  report: ['无需'], // 产品报告
  report_type: '', // 报告形式
  cross_board: 1, // 打叉板
  paper: 1, // 隔白纸
  user_stamp: 1, // 客编
  deduct_type: 2, // 订单支付方式
  deduct_limit: '', // 自动扣款差额范围
  insurance_type: 0, // 品质赔付方式
  insurance: '', // 品质赔付 PCBA单片成本价
  deltime: '正常48小时', // 交期要求
  is_need_smt: '', // SMT贴片
  invoice: '不需要', // 发票类型
  bill_id: '', // 发票抬头id
  province: '', // 省份id
  city: '', // 城市id
  express: '', // 快递id
  aid: 372, // 收货地址id
  address: '', // 收货地址
  recevtel: '', // 收货人手机号
  orderman_id: '', // 下单人信息id
  orderman: '', // 下单人
  ordertel: '', // 下单人电话
  eq_notice: '', // 工程EQ处理方式
  engineer_id: '', // 已选工程师信息id
  hq_pack: 1, // 包装要求
  has_period: 2, // 华秋标识和制造周期
  period_format: 1, // 周期格式
  sh: 0, // 审核通知
  fh: 0, // 发货通知
  fhp: 0, // 送货单
  fhd_title: '', // 收据抬头
  label_remark: '', // 内包装标签备注
  note: '', // 订单备注
  /** 拼版工具相关字段 start **/ 
  pnl_usage_rate: '', // 拼版利用率
  board_mode: '', // 拼版模式 0-常规 1-倒扣
  invertedx: 0, // X方向 0-无 1-有
  invertedy: 0, // Y方向 0-无 1-有
  inverted_one: 0, // 第一个方向开始 0-无 1-有
  pcs_cols_space: 0, // 列距
  pcs_row_space: 0, // 行距
  gap_cols_space: 0, // 工艺边左右间距
  gap_row_space: 0, // 工艺边上下间距
  /** 拼版工具相关字段 end **/ 
}

//  HDI 初始化的值
export const HDI_DEFAULT_FORM = {
  blind: 1, // 盲埋孔
  blayer: 4, // 板子层数
  board_tg: 'TG130', // TG值
  lineweight: 3.5, // 最小线宽/线距
  vias: 0.2, // 最小孔径
}

//  FR-4 拼版工具默认参数
export const FR4_BOARD_DEFAULT_FORM = {
  pnl_usage_rate: '', // 拼版利用率
  length: undefined, // 板长
  width: undefined, // 板宽
  board_mode: '0', // 拼版模式 0-常规 1-倒扣
  inverted: '', // 前端字段--控制拼版方向为单选 0-(X方向=1 Y方向=0) 1-(X方向=0 Y方向=1)
  invertedx: 0, // X方向 0-无 1-有
  invertedy: 0, // Y方向 0-无 1-有
  inverted_one: 0, // 从第一个方向开始 0-无 1-有
  layoutx: 1, // 列
  layouty: 1, // 行
  pcs_cols_space: 0, // 列距
  pcs_row_space: 0, // 行距
  sidedirection: '无', // 工艺边方向 Y-左右 X-上下 XY-四边
  sidewidth: 0, // 工艺边宽
  gap_cols_space: 0, // 工艺边左右间距
  gap_row_space: 0, // 工艺边上下间距
}

// FPC 默认参数
export const FPC_DEFAULT_FORM = {
  place_source_type,
  board_type: 'FPC', // 产品类型
  blayer: 2, // 板子层数
  pbnum: 1, // 拼版款数
  units: 1, // 出货形式
  length: '', // 尺寸 长
  width: '',  // 尺寸 宽
  layoutx: 1, // 拼版规则 横片数量
  layouty: 1,  // 拼版规则 纵片数量
  sidedirection: '无', // 工艺边框 方向
  sidewidth: 0, // 工艺边框 宽度
  bcount: '', // 单片数量
  material: '有胶电解', // 材料
  pi_thickness: 12.5, // PI 厚度
  bheight: '', // 板子厚度
  copper: '0.33', // 外层铜厚
  insidecopper: 0, // 内层铜厚
  lineweight: 6, // 最小线宽/线距
  vias: 0.3, // 最小孔径
  cover: '覆盖膜双面', // 阻焊类型
  color: '黄色', // 阻焊颜色
  charcolor: '白色', // 字符颜色
  spray: '沉金', // 表面处理
  cjh: 1, // 沉金厚度
  electrogilding_thickness: 1, // 电金厚度
  forming_type: '激光成型', // 成型方式
  reinforce: '', // 补强(前端字段)
  reinforce_steel: '', // 钢片补强，0否、1是
  steel_thickness: 0.1, // 钢片补强厚度
  reinforce_aluminium: '', // 铝片补强，0否、1是
  aluminium_thickness: 0.3, // 铝片补强厚度
  reinforce_fr4: '', // FR-4补强，0否、1是
  fr4_thickness: 0.1, // FR-4补强厚度
  reinforce_fingerpi: '', // 手指位PI补强，0否、1是
  fingerpi_thickness: 0.1, // 手指位PI补强厚度
  reinforce_otherpi: '', // 其他位PI补强，0否、1是
  otherpi_thickness: 0.05, // 其他位PI补强厚度
  back_gum_type: '无', // 背胶
  electromagnetic_membrane: 0, // 电磁膜
  conducting_resin: 0, // 导电胶
  impendance: 0, // 阻抗
  overlay_type: 2, // 层压顺序
  overlay_seq: '', // 层压顺序
  test: '', // 测试方式
  review_file: '', // 确认生产稿
  report: ['无需'], // 产品报告
  report_type: '', // 报告形式
  cross_board: 1, // 打叉板
  deltime: '', // 交期要求
  is_need_smt: '', // SMT贴片
  invoice: '不需要', // 发票类型
  bill_id: '', // 发票抬头id
  province: '', // 省份id
  city: '', // 城市id
  express: '', // 快递id
}

// SMT 默认参数
export const SMT_DEFAULT_FORM = {
  number: '', // 生产数量
  application_sphere: '', // 产品领域
  buy_bom: 1, // BOM来料方式
  file_bom: '', // BOM文件
  bom_file_name: '', // BOM文件名称
  bom_id: '', // BOM id
  bom_sn: '', // BOM单号
  custom_pcb_ban: 1, // PCB
  file_pcb: '', // PCB文件
  pcb_file_name: '', // PCB文件名称
  pcb_order_sn: '', // PCB单号
  file_patch: '', // SMT文件
  smt_file_name: '', // SMT文件名称
  is_pcb_soft_board: 0, // PCB板类型
  pcb_ban_width: '', // PCB拼版尺寸 长
  pcb_ban_height: '', // PCB拼版尺寸 宽
  pcb_ban_x: '', // PCB拼版规则 x方向个数
  pcb_ban_y: '',  // PCB拼版规则 y方向个数
  single_or_double_technique: 2, // 单/双面
  bom_material_type_number: '', //BOM物料
  patch_material_type: 1, // 贴片物料种类
  patch_pad_number: 1, // 单片贴片点数
  is_plug: 1, // 焊接插件料
  plug_material_type: 1, // 插件物料种类
  plug_number: 1, // 插件焊盘数
  steel_type: 0, // 钢网
  is_steel_follow_delivery: 0, // 钢网随货发
  need_conformal_coating: 0, // 刷三防漆
  is_dodge_solder_joint: 0, // 规避焊点及测试点
  solder_paste_type: 1, // 制程要求
  is_assembly_weld: 0, // 装配焊接
  is_material_baking: 0,// 物料烘烤
  is_assemble: 0, // 成品组装
  need_split: 0, // 拆板出货
  is_layout_cleaning: 0, // 板面清洁
  is_welding_wire: 0, // 焊接线材
  is_increase_tinning: 0, // 线路/焊盘加锡
  is_test: 0, // 功能测试
  is_program_burning: 0, // 程序烧录
  x_ray_unit_number: '', // X-Ray单板数
  x_ray_number: '', // X-Ray元件数
  is_first_confirm: '', // 样品图确认
  packing_type: 1, // 包装类型
  postscript: '', // 工艺备注
  gain_order_type: 0, // 配送方式 0-快递寄送 1-上门自提	
  province_id: '', // 省份id
  express: '', // 快递方式 1 顺丰 3-跨越标快
  express_type: '', // 快递类型 1为寄付，2为到付
  order_info: { // 前端字段（需移到与smt_params同一级）
    one_stop_temp_id: 0, // PCBA订单id
    bom_self_id: 0, // BOM自助匹配选型id
    pcb_order_sn: '', // pcb单号
    bom_id: '', // bom id
    bom_sn: '', // bom单号
    goods_name: '', // 商品名称
    remark: '', // 订单备注
    add_user: '', // 工程师
    add_user_tel: '', // 工程师电话
    add_user_email: '', // 工程师邮箱
    invoice_together: 1, // 1：一起开票，2：不一起开票
    vat_type: '', // 发票类型,1:纸质增值税 (专用) 发票,0:增值税 (普通) 发票,3:不开发票,4:数电增值税 (专用) 发票
    pickup_uname: '', // 提货人姓名
    pickup_phone: '', // 提货人手机号
    is_mounting_surface_type: true, // 是否能修改贴装面
    model_selection_type: 0, // bom器件来源类型：0-无 1-全部完成选型 2-部分完成选型 3-全部客供 4-全部不贴
  },
  address: {}, // 收货地址信息（前端字段, 需移到与smt_params同一级）
  invoice: {}, // 发票信息（前端字段, 需移到与smt_params同一级）
  pcbParams: {}, // 关联已在华秋下单的PCB参数（前端字段, 需移到与smt_params同一级的pcb_params）
  agreen_protocol: false, // 是否已同意协议
}

// 钢网 默认参数
export const STENCIL_DEFAULT_FORM = {
  stencil_frame: '钢网', // 边框类型
  stencil_type: '锡膏网', // 钢网类型
  printing_type: 1, // 印刷方式
  elec_tropolishing: 0, // 抛光工艺
  stencil_side: 3, // 制作方式
  stencil_size: '37*47', // 钢网尺寸
  stencil_thickness: '0.12', // 钢网厚度
  stencil_num: 1, // 数量
  existing_fiducials: '无', // 基准孔
  engineering_require: 1, // 工程处理要求
  deltime: '24小时',
  ship_name: '顺丰寄付',
  province: 6,
  city: 77
}
