// 是否是PCB站点
const isPcbSite = process.env.VUE_APP_SITE === 'PCB'

// 神策埋点
export const sensorsTrack = (eventName, data = {}) => {
  const params = {
    current_title: document.title,
    current_url: document.location.href,
    from_channel: isPcbSite ? '新版PCB' : 'DFM',
  }
  if (window.sensors) {
    window.sensors.track(eventName, { ...params, ...data })
  }
}
