<template>
  <!-- 快递选项 -->
  <RadioTable
    v-model="val"
    radioLabel="#"
    :loading="loading"
    :height="230"
    :radioKey="radioKey"
    :columns="columns"
    :tableData="options"
    @change="handleChange"
  >
    <template slot="empty">
      暂无快递
    </template>
  </RadioTable>
</template>

<script>
import RadioTable from '@@/components/radioTable/index'

import { getPcbExpress } from '@@/api/pcb'
import { getSmtExpress } from '@@/api/smt'
import { getStencilExpress } from '@@/api/pcb'
import { getPcbaExpress } from '@@/api/pcba'

// SMT快递id与支付类型连接符
export const SMT_EXPRESS_LINKER = '_'

export default {
  props: {
    mountRef: {
      type: Function,
      default: () => () => {}
    },
    value: {
      type: [String, Number],
      default: ''
    },
    radioKey: {
      type: String,
      default: 'shipping_id'
    },
    type: {
      type: String,
      validator: function (value) {
        return ['PCB', 'SMT', 'STENCIL'].includes(value)
      }
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    RadioTable
  },
  mounted() {
    this.mountRef({
      getList: () => this.getList()
    })
  },
  data() {
    return {
      loading: false,
      val: this.value,
      options: []
    }
  },
  computed: {
    currentItem() {
      const { val, radioKey, options } = this
      return options.find(item => item[radioKey] === val) || {}
    },
    columns() {
      const columns = [
        { label: '快递名称', prop: 'shipping_name', minWidth: 120 },
        { label: '快递费', prop: 'freight_amount', formatter: row => row.shipping_pay_type == 2 ? '到付' : `￥${row.freight_amount}` },
      ]
      if (this.type === 'PCB') {
        columns.push({ label: '预计送货时效', prop: 'shipping_time', width: 100 })
      }
      return columns
    }
  },
  watch: {
    value(newVal) {
      this.val = !isNaN(Number(newVal)) ? Number(newVal) : newVal
    }
  },
  // created() {
  //   this.getList()
  // },
  methods: {
    handleEmit() {
      const val = this.val
      this.$emit('input', val)
    },
    handleChange(item) {
      this.handleEmit()
      this.$emit('change', item)
    },
    // 获取快递列表
    async getList() {
      try {
        const res = await {
          PCB: this.getPcbExpress,
          // SMT: this.getSmtExpress,
          SMT: this.getPcbaExpress,
          STENCIL: this.getStencilExpress
        }[this.type]()

        this.options = res
        // 当有选中快递 且 选中的快递不在选项中时，清空选中的快递
        if (this.val && !res.map(i => i.shipping_id).includes(this.val)) {
          this.val = ''
          this.handleChange()
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 获取PCB快递列表
    async getPcbExpress() {
      return await getPcbExpress(this.params)
        .then(res => {
          if (!res) return []
          const { retCode, result } = res
          return retCode === 0 ? result : []
        })
    },
    // 获取SMT快递列表
    async getSmtExpress() {
      return await getSmtExpress(this.params)
        .then(res => {
          if (!res) return []
          const { suc, body } = res
          // SMT的快递 shipping_id 不是唯一的，需要与支付类型拼接
          return suc ? (body || []).map(item => ({...item, shipping_id: `${item.shipping_id}${SMT_EXPRESS_LINKER}${item.shipping_pay_type}`})) : []
        })
    },
    // 获取钢网快递列表
    async getStencilExpress() {
      return await getStencilExpress(this.params)
        .then(res => {
          if (!res) return []
          const { retCode, result } = res
          return retCode === 0 ? result : []
        })
    },
    // 获取PCBA快递列表
    async getPcbaExpress() {
      return await getPcbaExpress(this.params)
        .then(res => {
          if (!res) return []
          const { retCode, result } = res
          // SMT的快递 shipping_id 不是唯一的，需要与支付类型拼接
          return retCode === 0 ? (result || []).map(item => ({...item, shipping_id: `${item.shipping_id}${SMT_EXPRESS_LINKER}${item.shipping_pay_type}`})) : []
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>