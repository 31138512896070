<!-- BOM匹配详情弹框 -->
<template>
  <el-dialog 
    width="1000px" 
    title="匹配详情" 
    top="100px" 
    append-to-body
    custom-class="bom-item-dialog"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :visible="isShow" 
    @close="close"
  >
    <div v-loading="loading" class="bom-item-dialog__content">
      <el-table
        border
        max-height="450"
        style="width: 100%"
        :data="bomData || []"
        :cell-class-name="cellClassName"
      >
        <el-table-column label="源需求" header-align="center">
          <el-table-column
            v-for="item in leftList" 
            :key="item.key"
            :type="item.key === 'index' ? 'index' : ''"
            :prop="item.key" 
            :label="item.label" 
            :width="item.width"
            show-overflow-tooltip
          ></el-table-column>
        </el-table-column>
        <el-table-column label="已选定物料(黄色背景行)，华秋工程师不再人工做资料确认" label-class-name="text-red">
          <el-table-column
            v-for="item in rightList" 
            :key="item.key"
            :prop="item.key" 
            :label="item.label" 
            :width="item.width"
            show-overflow-tooltip
          ></el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { checkBomMatchItem } from '@@/api/pcba'

export default {
  data() {
    return {
      isShow: false,
      loading: false,
      bomData: [],
      leftList: [
        { key: 'index', label: '#', width: 30 },
        { key: 'cusLocation', label: '位号', width: 140 },
        { key: 'cusEncap', label: '封装', width: 100 },
        { key: 'cusGoodsOtherName', label: '规格参数', width: 100 },
        { key: 'cusGoodsName', label: '型号', width: 140 },
        { key: 'cusBrand', label: '品牌', width: 100 },
        { key: 'cusCatName', label: '分类', width: 100 },
        { key: 'cusGoodsSn', label: '我的编号', width: 100 }
      ],
      rightList: [
        { key: 'itemStatus', label: '选型结果', width: 100 },
        { key: 'goodsName', label: '型号', width: 140 },
        { key: 'brandName', label: '品牌', width: 100 },
        { key: 'encap', label: '封装', width: 100 },
        { key: 'dosage', label: '单机用量', width: 80 },
        { key: 'loss', label: '备损/差异', width: 80 },
        { key: 'totalNum', label: '总量', width: 80 },
        { key: 'unitPrice', label: '原单价(含税)', width: 100 },
        { key: 'discountUnitPrice', label: '折扣单价(含税)', width: 110 },
        { key: 'subtotal', label: '小计', width: 80 },
      ]
    }
  },
  methods: {
    open(bomId) {
      this.getList(bomId)
      this.isShow = true
    },
    close() {
      this.isShow = false
    },
    getList(oneStopBomId) {
      this.loading = true
      checkBomMatchItem({ oneStopBomId })
        .then(res => {
          if(!res) return
          const { retCode, result } = res
          if (retCode === 0) {
            this.bomData = result
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      const { leftList, rightList } = this
      const leftLen = leftList.length
      // 序号
      if (columnIndex === 0) {
        return 'bg-grey'
      }
      // 左边单元格
      if (columnIndex < leftLen) {
        return 'bg-lightGreen'
      }
      // 右边单元格
      let className = []
      if (['在华秋购买', '在华秋订购'].includes(row.itemStatus)) {
        className.push('bg-yellow')
      }
      if (column.property === 'itemStatus' && row.itemStatus === '不贴') {
        className.push('text-red')
      }
      const textGreyKeys = rightList.map(item => item.key).filter(key => ['/', '待报价'].includes(row[key]))
      if (textGreyKeys.includes(column.property)) {
        className.push('text-grey')
      }
      return className.join(' ')
    }
  }
}
</script>

<style lang="scss">
.bom-item-dialog {
  $barderColor: #d7d7d7;
  .el-dialog__header .el-dialog__title {
    font-weight: 700;
    color: $--color-text-primary;
  }
  .el-dialog__body {
    padding: 0 20px 40px;
  }
  .el-dialog__footer {
    padding-bottom: 30px;
  }

  .bom-item-dialog__content {
    .el-table {
      border-color: $barderColor;
      border-right: 1px solid $barderColor;
      th.el-table__cell {
        padding: 0;
        color: #333;
        background-color: #f2f2f2;
        border-color: $barderColor;
      }

      td.el-table__cell {
        padding: 0;
        color: #333;
        border-color: $barderColor;
      }

      .bg-grey {
        background-color: #f2f2f2;
      }
      .bg-lightGreen {
        background-color: #f7fcfb;
      }
      .bg-yellow {
        background-color: #ffff80;
      }
      .text-red {
        color: #FF4D4F !important;
      }
      .text-grey {
        color: #AAAAAA !important;
      }
    }
  }
}
</style>