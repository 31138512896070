<template>
  <el-table 
    v-loading="loading"
    :class="`radio-table radio-table--${borderStyle}`"
    :max-height="height"
    :highlight-current-row="true"
    :data="tableData"
    :row-class-name="rowClassName" 
    @row-click="handleRowClick"
  >
    <el-table-column class-name="radio-table__radioColumn" width="30" :label="radioLabel">
      <template slot-scope="{ row }">
        <span :class="{ 'radio-table__radio': true, selected: row[radioKey] == val }"></span>
      </template>
    </el-table-column>
    <el-table-column
      v-for="item in columns"
      :key="item[radioKey]"
      :width="item.width || '—'"
      :min-width="item.minWidth || '—'"
      :prop="item.prop" 
      :label="item.label"
      :class-name="item.prop === radioKey ? 'current-column' : ''"
      :show-overflow-tooltip="item.showOverflowTooltip"
    >
      <!-- 表头插槽 -->
      <template v-if="item.headerSlot">
        <template slot="header">
          <slot :name="item.headerSlot" />
        </template>
      </template>
      <!-- 内容插槽 -->
      <template slot-scope="{ row }">
        <slot v-if="item.slot" :name="item.slot" v-bind:row="row" />
        <template v-else>
          {{ item.formatter ? item.formatter(row) : row[item.prop] }}
        </template>
      </template>
    </el-table-column>

    <template v-for="(slot, key) in $scopedSlots" :slot="key" slot-scope="scope">
      <slot :name="key" v-bind="scope"></slot>
    </template>
    <template v-for="(slot, key) in $slots" :slot="key">
      <slot :name="key"></slot>
    </template>
  </el-table>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 表格线 solid-实线 dashed-虚线
    borderStyle: {
      type: String,
      default: 'solid'
    },
    height: {
      type: [Number, String],
      default: undefined
    },
    // 选中值的key
    radioKey: {
      type: String,
      default: ''
    },
    radioLabel: {
      type: String,
      default: ''
    },
    // 表头数据
    // { prop: xxx, label: xxx, width: xxx, minWidth: xxx, slot: xxx, formatter: row => {} }
    columns: {
      type: Array,
      default: []
    },
    tableData: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      val: this.value,
      currnetRow: {}
    }
  },
  watch: {
    value(newVal) {
      this.val = newVal
    }
  },
  methods: {
    handleEmit() {
      const { val, currnetRow } = this
      this.$emit('input', val)
      this.$emit('change', currnetRow)
    },
    handleRowClick(row) {
      this.val = row[this.radioKey]
      this.currnetRow = row
      this.handleEmit()
    },
    rowClassName({ row, rowIndex }) {
      const { val, radioKey } = this
      return row[radioKey] == val ? 'current-row' : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.radio-table {
  // 实线
  &.radio-table--solid {
    border: 1px solid $--input-border-color-disabled;
    border-radius: 4px;
    &::before {
      height: 0;
    }
    // 覆盖表格样式
    ::v-deep {
      // 头部背景
      th.el-table__cell {
        background: $--background-disabled;
      }
      .el-table__header {
        color: $--color-text-secondary;
      }
      .el-table__body {
        color: $--color-text-primary;
      }
      // hover背景
      tr:hover>td.el-table__cell,
      .current-row>td.el-table__cell {
        background: $--radio-checked-background-color;
      }
      .current-row .current-column {
        font-weight: 700;
      }
      .el-table__cell {
        cursor: pointer;
      }
      // 表格高度
      .el-table__cell {
        padding: 0;
        &>.cell {
          line-height: 36px;
          font-size: 13px;
        }
      }
      // 底部的线
      .el-table__row:last-child td {
        border-bottom: 0;
      }
      &::before {
        display: none;
      }
    }
  }
  // 虚线
  &.radio-table--dashed {
    padding: 5px;
    border: 1px dashed $--input-border-color;
    border-radius: 5px;
    // 覆盖表格样式
    ::v-deep {
      // 头部背景
      th.el-table__cell {
        background: #fff;
      }
      thead {
        color: #555;
      }
      // hover背景
      tr:hover>td.el-table__cell,
      .current-row>td.el-table__cell {
        background: $--radio-checked-background-color;
      }
      .el-table__cell {
        cursor: pointer;
        border-bottom: 0;
      }
      th.el-table__cell {
        border-bottom: 1px dashed $--input-border-color;
      }
      // 表格高度
      .el-table__cell {
        padding: 0;
        &>.cell {
          line-height: 36px;
          font-size: 13px;
        }
      }
      // 底部的线
      .el-table__row:last-child td{
        border-bottom: 0;
      }
      &::before {
        display: none;
      }
    }
  }
  
  .radio-table__radio {
    display: block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid $--form-color-base;
    position: relative;
    box-sizing: border-box;
    &.selected {
      &::after {
        position: absolute;
        top: 2px;
        left: 2px;
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $--form-color-base;
      }
    }
  }

  ::v-deep {
    .el-table__header {
      .el-table__cell.gutter {
        border-bottom: 1px solid #EBEEF5;
      }
    }
    .el-table__body-wrapper {
      .el-table__body {
        width: 100% !important;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(144,147,153,.5);
      }
    }
    .el-loading-mask {
      .el-loading-spinner {
        top: 50% !important;
        .path {
          stroke: $--form-color-base;
        }
      }
    }
  }
}
</style>